import { fabric } from 'fabric'
import DrawSectorFabric from './DrawSectorFabric'
import { COLORS_CODE } from '../config/colorCodes'

class GomFabric {
    constructor(canvasRef, { locationName = 'Location or event name', backgroundColor = '#f0f0f0', width = 800, height = 600, zoom = 1, grid = { size: 0, color: '#e0e0e0', opacity: '0.8' } }) {
        if (!canvasRef || !canvasRef.current) {
            console.error('Invalid canvas reference!')
            return
        }

        this.GomCanvas = new fabric.Canvas(canvasRef.current, {
            backgroundColor,
            width,
            height
        })

        // Drag activado con tecla ALT
        this.setDrag()

        // Zoom inicialización
        this.zoom = zoom

        // Canvas Locked
        this.isLocked = false

        // Escala
        this.scale = 1

        // Asegurarse de inicializar this.grid
        this.grid = {
            size: grid.size,
            color: grid.color,
            opacity: grid.opacity
        } // Asignar directamente el objeto grid recibido
        this.gridGroup = null
        if (this.grid.size > 0) {
            this.setGrid(this.grid)
        }

        // Is drawwing 
        this.isDrawingActive = false

        // Dates
        this.locationName = locationName
        this.dateTimeStart = new Date()
        this.dateTime = new Date()

        // DRAW Funtions
        this.DrawSectorFabric = null
    }

    setDrag = () => {
        // Habilitar el lienzo para interactuar con panning
        this.addOn('mouse:down', (event) => {
            console.log("hola canvas")
            if (event.e.altKey) { // Solo permitir panning si se mantiene presionada una tecla (por ejemplo, Alt)
                this.GomCanvas.isDragging = true
                this.GomCanvas.lastPosX = event.e.clientX
                this.GomCanvas.lastPosY = event.e.clientY
            }
        })

        this.addOn('mouse:move', (event) => {
            if (this.GomCanvas.isDragging) {
                // Calcular la diferencia de posición
                const deltaX = event.e.clientX - this.GomCanvas.lastPosX
                const deltaY = event.e.clientY - this.GomCanvas.lastPosY

                // Modificar la vista del canvas
                this.GomCanvas.relativePan(new fabric.Point(deltaX, deltaY))

                // Actualizar la posición para el siguiente movimiento
                this.GomCanvas.lastPosX = event.e.clientX
                this.GomCanvas.lastPosY = event.e.clientY
            }
        })

        this.addOn('mouse:up', () => {
            // Terminar el movimiento
            this.GomCanvas.isDragging = false
        })
    }

    // Add on events on
    addOn = (eventName, eventFunction) => this.GomCanvas.on(eventName, eventFunction)

    // Remove on events off
    addOff = (eventName) => this.GomCanvas.off(eventName)

    // Add element to canvas
    add = (element) => {
        this.GomCanvas.add(element)
        this.requestRenderAll()
    }

    addRectangle1 = ({ fill = 'blue', width = 50, height = 50, position = { left: 10, top: 10 } }, parentNode) => {
        const rect = new fabric.Rect({
            fill: fill,
            width: width,
            height: height,
            left: position.left,
            top: position.top,
            hasControls: false, // Esto desactiva los controles predeterminados, lo que puede ser útil si quieres un menú
            lockMovementX: false,
            lockMovementY: false,
            scaleX: this.scale, // Escala horizontal
            scaleY: this.scale,   // Escala vertical
        })

        this.add(rect)

        // Variable para almacenar el menú
        let menu

        // Crear el menú cuando se seleccione el rectángulo
        const createMenu = () => {
            if (menu) return // Evita crear múltiples menús si ya existe uno

            menu = document.createElement('div')
            menu.style.position = 'absolute'
            menu.style.backgroundColor = '#fff'
            menu.style.border = '1px solid #ddd'
            menu.style.borderRadius = '4px'
            menu.style.padding = '10px'
            menu.style.display = 'none' // Inicialmente oculto
            //menu.style.zIndex = '2147483647'
            menu.style.width = '200px'

            // Crear los botones de forma manual
            const deleteButton = document.createElement('button')
            deleteButton.innerText = 'Delete'
            deleteButton.onclick = handleDelete

            const editButton = document.createElement('button')
            editButton.innerText = 'Edit'
            editButton.onclick = handleEdit

            // Añadir los botones al menú
            menu.appendChild(deleteButton)
            menu.appendChild(editButton)

            parentNode.appendChild(menu)
        }

        // Función para manejar la eliminación
        const handleDelete = () => {
            this.remove(rect) // Eliminar rectángulo del canvas
            if (menu && parentNode.contains(menu)) {
                parentNode.removeChild(menu) // Eliminar el menú si fue creado y aún está en el DOM
            }
        }

        // Función para manejar la edición
        const handleEdit = () => {
            alert('Edit functionality here!')
        }

        // Mostrar el menú cuando el rectángulo es seleccionado
        rect.on('selected', (e) => {
            this.dateTime = new Date()

            createMenu()

            menu.style.display = 'block' // Mostrar el menú
            //menu.style.zIndex = 2147483647
            // Obtener las coordenadas y dimensiones del rectángulo
            const { left, top } = rect.getBoundingRect()
            const centerX = left + rect.getScaledWidth() / 2
            const centerY = top + rect.getScaledHeight() // Parte inferior del rectángulo

            // Posicionar el menú tomando en cuenta el escalado
            menu.style.left = `${centerX - menu.offsetWidth / 2}px`
            menu.style.top = `${centerY + 10}px` // 10px de separación debajo

            this.handleObjectSelected(e)
        })

        // Actualizar la posición del menú cuando el rectángulo se mueve
        rect.on('moving', (e) => {
            this.dateTime = new Date()

            const movingObject = e.transform ? e.transform.target : e.target
            if (movingObject) {
                if (menu && parentNode.contains(menu)) {
                    // Obtener las coordenadas y dimensiones del rectángulo
                    const { left, top } = rect.getBoundingRect()
                    const centerX = left + rect.getScaledWidth() / 2
                    const centerY = top + rect.getScaledHeight() // Parte inferior del rectángulo

                    // Posicionar el menú tomando en cuenta el escalado
                    menu.style.left = `${centerX - menu.offsetWidth / 2}px`
                    menu.style.top = `${centerY + 10}px` // 10px de separación debajo
                }
            }

            this.handleObjectMoving(e)
        })

        rect.on('modified', (e) => {
            this.handleObjectModified(e)
        })

        // Ocultar el menú cuando el rectángulo es deseleccionado
        rect.on('deselected', () => {
            if (menu && parentNode.contains(menu)) {
                menu.style.display = 'none' // Ocultar el menú si no hay nada seleccionado
            }
        })

        // Evitar que el menú desaparezca al hacer clic sobre él
        if (menu) {
            menu.addEventListener('click', (e) => {
                e.stopPropagation() // Prevenir que el evento de click afecte a la deselección del canvas
            })
        }

        // Limpiar el menú cuando el rectángulo se elimina
        rect.on('removed', () => {
            if (menu && parentNode.contains(menu)) {
                parentNode.removeChild(menu)
            }
        })

    }

    addRectangle = ({ fill = 'blue', width = 50, height = 50, position = { left: 10, top: 10 } }) => {
        const rect = new fabric.Rect({
            fill: fill,
            width: width,
            height: height,
            left: position.left,
            top: position.top,
        })
        this.add(rect)

        // Manejar el clic sobre el rectángulo
        rect.on('mousedown', () => {
            this.dateTime = new Date()
            //alert('mousedown '+this.dateTime) // Aquí puedes abrir tu modal o cualquier otro tipo de diálogo
        })
        // Manejar el clic sobre el rectángulo para dispositivos táctiles (tablet, móvil)
        rect.on('touchstart', () => {
            this.dateTime = new Date()
            //alert('touchstart '+this.dateTime) // Aquí puedes abrir tu modal o cualquier otro tipo de diálogo
        })
    }

    resize = ({ width = 800, height = 600 }) => {
        this.setWidth(width)
        this.setHeight(height)
        this.setGrid(this.grid)
        this.renderAll()
    }

    setWidth = (width) => this.GomCanvas.setWidth(width)
    getWidth = () => this.GomCanvas.getWidth()

    setHeight = (height) => this.GomCanvas.setHeight(height)
    getHeight = () => this.GomCanvas.getHeight()

    renderAll = () => this.GomCanvas.renderAll()

    requestRenderAll = () => this.GomCanvas.requestRenderAll()

    getElement = () => this.GomCanvas.getElement()

    getElementParentNode = () => this.GomCanvas.getElement().parentNode

    dispose = () => this.GomCanvas.dispose()

    remove = (element) => this.GomCanvas.remove(element)

    setGrid = ({ size = 0, color = '#e0e0e0', opacity = '0.8' } = {}) => {
        const { width, height } = this.GomCanvas

        this.grid = {
            size,
            color,
            opacity
        }
        // Si ya hay un grid, no hacer nada
        if (this.gridGroup) {
            // Si el grid ya existe, eliminarlo
            this.remove(this.gridGroup)
            if (size === 0) return
        }

        // Crear un grupo de líneas para la malla
        const lines = []
        const createLine = (coords) => new fabric.Line(coords, {
            stroke: color,
            strokeWidth: 1,
            selectable: false,
            evented: false,
            opacity
        })

        // Líneas verticales
        for (let i = 0; i <= width; i += size) {
            lines.push(createLine([i, 0, i, height]))
        }

        // Líneas horizontales
        for (let j = 0; j <= height; j += size) {
            lines.push(createLine([0, j, width, j]))
        }

        // Crear un grupo para la malla y añadirlo al canvas
        const gridGroup = new fabric.Group(lines, {
            selectable: false,
            evented: false,
            top: 0,
            left: 0,
            data: {  // Data con propiedades específicas.
                grid: true,
            },
            scaleX: 1 / this.zoom,
            scaleY: 1 / this.zoom,
        })

        this.add(gridGroup)
        // Guardar la referencia al grupo del grid para poder eliminarlo después
        this.gridGroup = gridGroup
        this.sendToBack(gridGroup) // Enviar la malla al fondo

    }

    sendToBack = (element) => {
        this.GomCanvas.sendToBack(element)
        if (this.gridGroup) {
            this.GomCanvas.sendToBack(this.gridGroup)
        }
        this.renderAll()
    }

    moveTo = (element, index) => {
        this.GomCanvas.moveTo(element, index)
    }

    getZoom = () => { return this.zoom }
    setZoom = (zoom) => {
        this.zoom = zoom
        this.GomCanvas.setZoom(zoom)
        this.setGrid(this.grid)
        this.discardActiveObject()
    }
    setZoomWheelActive = (callback = null) => {
        this.addOn('mouse:wheel', (event) => {
            event.e.preventDefault()
            event.e.stopPropagation()

            // Obtener el factor de zoom
            const delta = event.e.deltaY
            let zoom = this.GomCanvas.getZoom()
            zoom *= 0.999 ** delta

            // Limitar el zoom
            zoom = Math.max(0.1, Math.min(20, zoom))
            //this.GomCanvas.zoomToPoint(new fabric.Point(event.e.offsetX, event.e.offsetY), zoom)
            this.setZoom(zoom)
            this.zoom = zoom
            if (typeof callback === 'function') {
                callback(zoom)
            }
        })
    }

    getLastupdateDateTime = () => {
        const now = this.dateTime
        const day = now.toLocaleDateString() // Fecha en formato 'DD/MM/YYYY' (depende de la configuración regional)
        const time = now.toLocaleTimeString() // Hora en formato 'HH:MM:SS'
        return `${day} ${time}` // Devuelve una cadena con la fecha y hora
    }

    getLastupdateDateTimeFileFormat = () => {
        const now = this.dateTime // Obteniendo la fecha y hora actual del contexto
        const year = now.getFullYear() // Año en formato YYYY
        const month = String(now.getMonth() + 1).padStart(2, '0') // Mes en formato MM (se suma 1 porque getMonth() devuelve un índice base 0)
        const day = String(now.getDate()).padStart(2, '0') // Día en formato DD
        const hours = String(now.getHours()).padStart(2, '0') // Hora en formato HH (24 horas)
        const minutes = String(now.getMinutes()).padStart(2, '0') // Minutos en formato MM
        const seconds = String(now.getSeconds()).padStart(2, '0') // Segundos en formato SS

        // Combina todos los valores en el formato requerido
        return `${year}${month}${day}${hours}${minutes}${seconds}`
    }

    setLastUpdateDateTime = () => this.dateTime = new Date()

    setDateTimeStart = (date) => this.dateTimeStart = date

    // Get canvas in PNG or JPG image format
    getCanvasImage = ({ imageFormat = 'png', imageQuality = 1, multiplier = 3 }) => {
        const canvasDataUrl = this.GomCanvas.toDataURL({
            format: imageFormat,    // Especifica el formato ('png' o 'jpeg')
            quality: imageQuality,  // Calidad de la imagen (para JPEG, de 0 a 1)
            multiplier: multiplier, // Factor de escala/resolución (por defecto 3)
        })
        const dateTimeString = this.getLastupdateDateTimeFileFormat()
        const link = document.createElement('a')
        link.href = canvasDataUrl
        link.download = `canvas_image_${dateTimeString}.${imageFormat}`
        link.click()
        URL.revokeObjectURL(link.href)
    }

    // Get canvas in JSON format
    getCanvasJSON = () => {
        const canvasJSON = this.toJSON()
        const jsonString = JSON.stringify(canvasJSON)

        const dateTimeString = this.getLastupdateDateTimeFileFormat()
        const blob = new Blob([jsonString], { type: 'application/json' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `canvas_${dateTimeString}.json`
        link.click()
        URL.revokeObjectURL(link.href)
    }

    // Canvas to JSON format
    toJSON = () => this.GomCanvas.toJSON(['data', 'gomObjectType', 'timestamp', 'createdAt', 'selectable', 'hoverCursor'])  // Exportar propiedades extra

    // Set new canvas from JSON canvas object
    setFromJSON = (jsonObject) => {
        this.GomCanvas.loadFromJSON(jsonObject, this.getRequestRenderAll, (object) => {
            const myobject = {
                ...object,
                loadedFromJSON: true,
            }
            return myobject
        })
    }

    // Set gom canvas name
    setLocationName = (locationName) => this.locationName = locationName

    // Delete all objects
    deleteAll = () => this.GomCanvas.clear()

    // Delete selected object
    deleteSelected = () => {
        const object = this.getActiveObject()
        if (object) {
            this.GomCanvas.remove(object)
            this.renderAll()
        }
    }

    // Get active object/s
    getActiveObject = () => this.GomCanvas.getActiveObject()
    getActiveObjects = () => this.GomCanvas.getActiveObjects()

    // Set active object/s
    setActiveObject = () => this.GomCanvas.setActiveObject()

    // Get all objects
    getObjects = () => this.GomCanvas.getObjects()

    // Set background image
    setBackgroundImage = (dataUrl) => {
        if (dataUrl) {
            fabric.Image.fromURL(dataUrl, (oImg) => {
                oImg.scaleToWidth(this.getWidth())
                //oImg.scaleToHeight(this.canvas.height)
                oImg.set({
                    originX: 'center',
                    originY: 'center',
                    left: this.getWidth() / 2,
                    top: this.getHeight() / 2,
                })
                //oImg.scale(this.canvas.width, this.canvas.height)
                this.GomCanvas.setBackgroundImage(
                    oImg,
                    () => {
                        this.GomCanvas.fire('backgroundImage:added', oImg)
                        this.renderAll()
                    },
                    {
                        opacity: 0.5,
                        //angle: 45,
                    }
                )
            })
        }
    }

    // Set background color
    setBackgroundColor = (color) => {
        // Asegúrate de que GomCanvas sea una instancia de fabric.Canvas
        if (this.GomCanvas && this.GomCanvas.setBackgroundColor) {
            this.GomCanvas.setBackgroundColor(color, this.GomCanvas.renderAll.bind(this.GomCanvas))
        } else {
            console.error('GomCanvas no está inicializado o no es una instancia de fabric.Canvas')
        }
    }

    getIsLocked = () => { return this.isLocked }

    toggleCanvasLock = (isLocked) => {
        this.isLocked = isLocked // Actualizar el estado interno, si necesario
        this.GomCanvas.selection = !isLocked // True si desbloqueado, false si bloqueado
        this.GomCanvas.forEachObject((obj) => {
            if (!obj.data?.grid) {  // Si no es el GRID bloqueamos o desbloqueamos
                obj.selectable = !isLocked
                obj.evented = !isLocked
            }
        })
        this.discardActiveObject()
        this.renderAll()
    }

    // Deselecciona el objeto activo (o los objetos activos si está en modo grupo)
    discardActiveObject = () => this.GomCanvas.discardActiveObject()

    getScale = () => { return this.scale }

    setScaleAll = (scale) => {
        this.scale = scale
        this.GomCanvas.forEachObject((obj) => {
            if (!obj.data?.localScaled && !obj.data?.grid) {  // Si el objeto no se ha escalado individualmente aplicamos escala general
                obj.scaleX = this.scale
                obj.scaleY = this.scale
            }
        })
        this.discardActiveObject()
        this.renderAll()
    }

    setCursor = (cursor = 'default') => this.GomCanvas.defaultCursor = cursor

    getPointer = (e) => this.GomCanvas.getPointer(e.e)

    isDrawing = () => {
        return this.isDrawingActive
    }

    setIsDrawingActive = (isDrawing) => {
        this.isDrawingActive = isDrawing
        this.GomCanvas.selection = isDrawing
    }

    // Método para iniciar la acción de dibujo del sector
    startDrawSector = (callBack) => {
        this.DrawSectorFabric = new DrawSectorFabric(this)
        if (this.isDrawingActive) {
            this.DrawSectorFabric = null
            console.warn('Drawing is already in progress.')
            return
        }

        // Configurar eventos necesarios
        this.addOn('mouse:down', this.DrawSectorFabric.drawSectorStart)
        this.addOn('mouse:dblclick', () => this.endDrawSector(callBack)) // Vincula el método de finalización
        this.setCursor('crosshair')
        this.setIsDrawingActive(true)

        if (typeof callBack === 'function') {
            callBack(this.isDrawing)
        }
    }

    // Método para finalizar la acción de dibujo del sector
    endDrawSector = (callBack) => {
        if (!this.isDrawingActive) {
            this.DrawSectorFabric = null
            console.warn('No active drawing to end.')
            return
        }

        // Finalizar el polígono y limpiar eventos
        const sectorGroup = this.DrawSectorFabric.drawSectorEnd()
        if (sectorGroup) {
            this.add(sectorGroup)
            this.sendToBack(sectorGroup)
            sectorGroup.on('selected', (e) => {
                console.log("e", e)
                console.log("sector ID:", e.target.data.id)
            })
        }

        this.addOff('mouse:down')
        this.addOff('mouse:dblclick')
        this.setCursor('default')
        this.setIsDrawingActive(false)
        this.setDrag()

        if (typeof callBack === 'function') {
            callBack(this.isDrawing)
        }
        this.renderAll()
        this.DrawSectorFabric = null
    }

    handleObjectMoving = (event) => {
        const movingObject = event.transform.target

        this._updateSectors(movingObject, (sector, isPartiallyInside) => {
            sector.set('opacity', isPartiallyInside ? 0.5 : 1)
        })
    }

    handleObjectModified = (event) => {
        const movingObject = event.transform.target

        this._updateSectors(movingObject, (sector, isPartiallyInside) => {
            if (isPartiallyInside) {
                movingObject.set('data', {
                    ...movingObject.data,
                    sectorId: sector.data.id,
                })
            }
            sector.set('opacity', 1)
        })
    }

    handleObjectSelected = (event) => {
        const movingObject = event.target

        this._updateSectors(movingObject, (sector, isPartiallyInside) => {
            if (isPartiallyInside) {
                movingObject.set('data', {
                    ...movingObject.data,
                    sectorId: sector.data.id,
                })
            }
            sector.set('opacity', 1)
        })
    }

    _updateSectors = (movingObject, callback) => {
        if (!movingObject) return

        // Actualizar coordenadas del objeto en movimiento
        movingObject.setCoords()

        // Obtener la transformación de la vista (zoom, pan, etc.)
        const viewportTransform = this.GomCanvas.viewportTransform || [1, 0, 0, 1, 0, 0]
        const zoomFactor = this.getZoom()

        // Obtener los polígonos con data.type = 'sector'
        const sectors = this.getObjects('group').filter(
            (obj) => obj.data?.type === 'sectorGroup'
        )

        // Obtener las coordenadas globales del objeto en movimiento
        //const objectCoords = movingObject.getCoords(true)

        // Obtener las coordenadas globales del objeto en movimiento, ajustadas al zoom
        const objectCoords = movingObject.getCoords(true).map((point) => ({
            x: (point.x * zoomFactor) + viewportTransform[4],
            y: (point.y * zoomFactor) + viewportTransform[5],
        }))

        sectors.forEach((sector) => {
            // Actualizar las coordenadas del polígono
            sector.setCoords()

            // Verificar si algún punto del objeto está dentro del polígono
            const isPartiallyInside = objectCoords.some((point) =>
                sector.containsPoint(point)
            )

            callback(sector, isPartiallyInside)
        })

        // Renderizar el canvas para reflejar los cambios visuales
        this.renderAll()
    }

}

export default GomFabric