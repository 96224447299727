import React, { useState, useEffect } from 'react'
import { useIntl } from "react-intl"
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'
import { BsGrid3X3 } from 'react-icons/bs'

const GridControl = ({ canvasRef }) => {
    const intl = useIntl()
    const [isGridActive, setIsGridActive] = useState(false)
    const [gridSize, setGridSize] = useState(25) // Almacena el tamaño del grid

    // Actualiza el estado local basado en canvasRef
    useEffect(() => {
        if (!canvasRef) return
        const gomCanvas = canvasRef
        setIsGridActive(gomCanvas.grid.size > 0)
    }, [canvasRef])

    const handleGridToggle = () => {
        const gomCanvas = canvasRef
        const newSize = isGridActive ? 0 : gridSize

        gomCanvas.setGrid({ size: newSize })
        setIsGridActive(newSize > 0); // Actualiza el estado local
    }

    const handleGridSizeChange = (size) => {
        const gomCanvas = canvasRef
        gomCanvas.setGrid({ size })
        setGridSize(size)
        setIsGridActive(true)
    }

    return (
        <ButtonGroup size="sm" aria-label="Grid controls">
            <Button
                title={isGridActive ? intl.formatMessage({ id: "hideGrid", defaultMessage: "Hide Grid" }) : intl.formatMessage({ id: "showGrid", defaultMessage: "Show grid" })}
                variant={isGridActive ? 'secondary' : 'light'} // Cambia según estado
                size="sm"
                onClick={handleGridToggle}
                className="d-inline-flex align-items-center"
            >
                <BsGrid3X3 />
            </Button>
            {/* Dropdown para cambiar el tamaño del grid */}
            <Dropdown as={ButtonGroup} drop='up-centered'>
                <Dropdown.Toggle variant="light" id="dropdown-basic" size='sm' title={intl.formatMessage({ id: "gridSize", defaultMessage: "Grid size" })}>
                    {gridSize}px
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {[10, 25, 50, 100].map((size) => (
                        <Dropdown.Item
                            key={size}
                            onClick={() => handleGridSizeChange(size)}
                        >
                            {size}px
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </ButtonGroup>
    )
}

export default GridControl
