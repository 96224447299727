import React, { useState } from 'react'
import { useIntl } from "react-intl"
import { Button, Image } from 'react-bootstrap'
import CopyModal from '../modal/CopyModal'

const LogoControl = ({ parentNode }) => {
    const intl = useIntl()
    const [showModal, setShowModal] = useState(false)

    const toggleShowModal = () => {
        setShowModal(!showModal)
    }

    return (
        <>
            <Button
                title={intl.formatMessage({ id: "gom", defaultMessage: "GOM Fire" })}
                variant="warning"                
                //className="d-inline-flex align-items-center"
                size='md'
                onClick={() => toggleShowModal()}
            >
                <Image src='/logo_gom_fire_trans_sm.png' height={24} />
            </Button>

            <CopyModal title={intl.formatMessage({ id: "gom", defaultMessage: "GOM Fire" })} show={showModal} onClose={() => toggleShowModal()} parentNode={parentNode} />
        </>
    )
}

export default LogoControl