module.exports = {
    OPENSTREETMAP_LAYER: {
        type: "base",
        name: "OpenStreetMap",
        attribution: '&copy; <a target="_blank" href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        maxZoom: 19
    },
    BASIC_LAYERS: [
        {
            type: "base",
            name: "IGN España",
            attribution: '&copy; <a target="_blank" href="https://www.ign.es">IGN España</a> contributors',
            url: 'https://tms-ign-base.idee.es/1.0.0/IGNBaseTodo/{z}/{x}/{-y}.jpeg',
            maxZoom: 17
        },
        {
            type: "base",
            name: "Sentinel2 PNOA MA",
            attribution: '&copy; <a target="_blank" href="https://www.scne.es/">Sistema Cartográfico Nacional de España</a> contributors',
            url: 'https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg',
            maxZoom: 19
        },
        {
            type: "base",
            name: "CartoDB Dark",
            attribution: '&copy; <a target="_blank" href="http://cartodb.com/attributions">CartoDB</a> contributors',
            url: 'https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png'
        },
        {
            type: "base",
            name: "Esri WorldImagery",
            url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
            attribution: '&copy; <a target="_blank" href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
        },
        {
            type: "base",
            name: "Google maps satellite",
            url: "https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}",
            attribution: '&copy; <a target="_blank" href="https://maps.google.com">Google maps</a> contributors'
        },
        {
            type: "wms",
            name: "SEDE Catastro",
            attribution: '&copy; <a href="https://www.sedecatastro.gob.es/" target="_blank">Dirección General de Catastro</a>',
            url: 'https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx',
            layers: 'CATASTRO',
            format: 'image/png',
            transparent: true,
            maxZoom: 20
        },
        {
            type: "wms",
            name: "CARTOCIUDAD",
            attribution: '&copy; <a href="https://www.cartociudad.es/" target="_blank">Cartociudad</a>',
            url: 'https://www.cartociudad.es/wms-inspire/direcciones-ccpp?SERVICE=WMS&',
            layers: ['AD.Address', 'codigo-postal'],
            format: 'image/png',
            transparent: true,
            maxZoom: 20
        }
    ]
}