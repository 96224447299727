import React from 'react'
import { useIntl } from "react-intl"
import ControlsMenu from './ControlsMenu'
import GridControl from '../controls/GridControl'
import DateTimeControl from '../controls/DateTimeControl'
import ZoomControl from '../controls/ZoomControl'
import HelpControl from '../controls/HelpControl'
import FullscreenControl from '../controls/FullscreenControl'
import CopyrightControl from '../controls/CopyrightControl'

const ToolsMenu = ({ containerRef, canvasRef }) => {
    const intl = useIntl()
    return (
        <ControlsMenu
            buttons={[
                <FullscreenControl parentNode={containerRef} />,
                <GridControl canvasRef={canvasRef} />,
                <ZoomControl canvasRef={canvasRef} />,
                <DateTimeControl />,
                <HelpControl label={intl.formatMessage({ id: "help", defaultMessage: "Help" })} parentNode={containerRef} />,
                <CopyrightControl label={intl.formatMessage({ id: "copyright", defaultMessage: "Copyright" })} parentNode={containerRef}  />,
            ]}
            position={{
                bottom: '10px',
                right: '10px'
            }}
        />
    )
}

export default ToolsMenu