import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { Dropdown, ButtonGroup } from 'react-bootstrap'
import { BsTrash, BsTrashFill, BsXCircleFill } from 'react-icons/bs'

const DeleteControl = ({ canvasRef }) => {
    const intl = useIntl()
    const [elementSelected, setElementSelected] = useState(false)

    const handleDeleteSelected = () => {
        if (canvasRef) {
            canvasRef.deleteSelected()
        }
    }

    const handleDeleteAll = () => {
        if (canvasRef) {
            canvasRef.deleteAll()
        }
    }

    // useEffect para actualizar `elementSelected` cuando el canvas cambia
    useEffect(() => {
        if (!canvasRef) return
        const onObjectSelected = (e) => {
            setElementSelected(true) // Hay objeto seleccionado
        }

        const onSelectionCleared = () => {
            setElementSelected(false) // No hay objeto seleccionado
        }

        // Suscribir los eventos de selección
        canvasRef.addOn('selection:created', onObjectSelected)
        canvasRef.addOn('selection:cleared', onSelectionCleared)

        // Limpiar los eventos cuando el componente se desmonte
        return () => {
            canvasRef.addOff('selection:created')
            canvasRef.addOff('selection:cleared')
        }
    }, [canvasRef])

    return (
        <Dropdown
            variant='secondary'
            as={ButtonGroup}
            drop='end'
        >
            <Dropdown.Toggle variant="light" size='md' id="dropdown-basic" title={intl.formatMessage({ id:"delete", defaultMessage:"Delete" })}>
                <BsTrash size={20} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Header><FormattedMessage id="delete" defaultMessage="Delete" /></Dropdown.Header>
                <Dropdown.Item
                    onClick={handleDeleteSelected}
                    disabled={!elementSelected}
                >
                    <BsXCircleFill />&nbsp;<FormattedMessage id="selected" defaultMessage="Delete selected" /></Dropdown.Item>
                <Dropdown.Item
                    onClick={handleDeleteAll}
                >
                    <BsTrashFill />&nbsp;<FormattedMessage id="all" defaultMessage="Delete all" /></Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DeleteControl
