import React, { useEffect, useState } from 'react'
import { useIntl } from "react-intl"
import { Button, InputGroup, Form, ButtonGroup } from 'react-bootstrap'
import { BsGeoAlt } from 'react-icons/bs'

const LocationControl = ({ canvasRef }) => {
    const intl = useIntl()
    const [isEditing, setIsEditing] = useState(false)
    const [inputValue, setInputValue] = useState('Cargando...')

    const handleClick = () => {
        setIsEditing(true) // Permitir edición al hacer doble clic
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value) // Actualizar el valor del input
        canvasRef.setLocationName(e.target.value)
    }

    const handleInputBlur = () => {
        setIsEditing(false) // Salir de la edición al perder el foco
    }

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            setIsEditing(false) // Salir de la edición al presionar Enter
        }
    }

    useEffect(() => {
        if (!canvasRef) return
        setInputValue(canvasRef.locationName)
    }, [canvasRef])

    return (
        <ButtonGroup>
            {isEditing ? (
                <InputGroup size='md'>
                    <InputGroup.Text><BsGeoAlt size={20} /></InputGroup.Text>
                    <Form.Control
                        maxLength={100}
                        type="text"
                        value={inputValue}
                        autoFocus
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onKeyDown={handleInputKeyDown}
                        size="md"
                        className="d-inline-flex align-items-center"
                    />
                </InputGroup>

            ) : (
                <Button
                    title={intl.formatMessage({ id: "editLocation", defaultMessage: "Edit location" })}
                    variant="light"
                    size="md"
                    className="d-inline-flex align-items-center"
                    onClick={handleClick} // Iniciar la edición
                >
                    <BsGeoAlt size={20} />&nbsp;{inputValue.length > 50 ? `${inputValue.slice(0, 50)}...` : inputValue}
                </Button>
            )}
        </ButtonGroup>
    )
}

export default LocationControl
