import React, { useState } from 'react'
import { useIntl } from "react-intl"
import { Button } from 'react-bootstrap'
import { BsPlusSquareDotted, BsStopCircle } from 'react-icons/bs'

const DrawSectorControl = ({ canvasRef }) => {
    const intl = useIntl()

    const [isDrawing, setIsDrawing] = useState(false)

    const handleDrawPolygon = () => {
        if (canvasRef) {
            if (!isDrawing) {
                canvasRef.startDrawSector(setIsDrawing)
            } else {
                canvasRef.endDrawSector(setIsDrawing)
            }
        }
    }

    return (
        <Button
            variant={isDrawing ? 'primary' : 'light'}
            onClick={handleDrawPolygon}
            title={intl.formatMessage({ id: "addSector", defaultMessage: "Add sector" })}
        >
            {isDrawing ? (
                <BsStopCircle size={20} />
            ) : (
                <BsPlusSquareDotted size={20} />
            )}
        </Button>
    )
}

export default DrawSectorControl
