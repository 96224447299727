import React from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { Form, NavDropdown } from 'react-bootstrap'
import { BsCloudDownload, BsCloudUpload, BsDownload, BsFileImage } from 'react-icons/bs'

const ExportControl = ({ canvasRef }) => {
    const intl = useIntl()
    const handleExportToPNG = () => {
        canvasRef.getCanvasImage({ format: 'png', quality: 1 })
    }

    const handleExportToJSON = () => {
        canvasRef.getCanvasJSON()
    }

    const handleImportFromJSON = async (event) => {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = () => {
                const jsonData = JSON.parse(reader.result)
                if (jsonData) {
                    canvasRef.setFromJSON(jsonData)
                }
            }
            reader.readAsText(file)
        }
    }

    return (
        <NavDropdown
            menuVariant='light'
            aria-label={intl.formatMessage({ id: "exportControls", defaultMessage: "Export controls" })}
            title={
                <>
                    <span className="d-none d-lg-inline-flex align-items-center">
                        <BsDownload size={20} title={intl.formatMessage({ id: "importExportCanvas", defaultMessage: "Import/Export Canvas" })} />
                    </span>
                    <span className="d-block d-lg-none d-inline-flex align-items-center">
                        <BsDownload size={20} title={intl.formatMessage({ id: "importExportCanvas", defaultMessage: "Import/Export Canvas" })} />&nbsp;<FormattedMessage id="exportImport" defaultMessage="Export/Import" />
                    </span>
                </>
            }
        >
            <NavDropdown.Item
                className="d-flex align-items-center"
                title={intl.formatMessage({ id: "exportCanvasImageToPng", defaultMessage: "Export canvas to image in PNG format" })}
                onClick={handleExportToPNG}
            >
                <BsFileImage />&nbsp;<FormattedMessage id="exportToPng" defaultMessage="Export to PNG" />
            </NavDropdown.Item>
            <NavDropdown.Item
                className="d-flex align-items-center"
                title={intl.formatMessage({ id: "exportCanvasAsciiToJson", defaultMessage: "Export canvas to ascii file in JSON format" })}
                onClick={handleExportToJSON}
            >
                <BsCloudDownload />&nbsp;<FormattedMessage id="exportToJson" defaultMessage="Export to JSON" />
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
                className="d-flex align-items-center"
                as='label'
                htmlFor='importJsonFile'
                title={intl.formatMessage({ id: "importCanvasFromJson", defaultMessage: "Import canvas from JSON file" })}
            >
                <BsCloudUpload />&nbsp;<FormattedMessage id="importFromJson" defaultMessage="Import from JSON" />
                <Form.Control
                    id="importJsonFile"
                    type="file"
                    onChange={handleImportFromJSON}
                    style={{ display: 'none' }}
                />
            </NavDropdown.Item>
        </NavDropdown>
    )
}

export default ExportControl
