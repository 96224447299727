import React, { useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Alert, Button, ButtonGroup } from "react-bootstrap"
import { MapContainer, TileLayer, LayersControl, WMSTileLayer, useMap, AttributionControl } from "react-leaflet"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import "leaflet-control-geocoder/dist/Control.Geocoder.css" // Estilo para el geocodificador
import 'leaflet-control-geocoder'  // Importar explícitamente leaflet-control-geocoder para habilitar el geocodificador https://www.liedman.net/leaflet-control-geocoder/docs/interfaces/geocoders.NominatimOptions.html

import markerSvg from "../../helper/markerIcons"
import exportMapToImage from "../../helper/exportMapToImage"
import { OPENSTREETMAP_LAYER, BASIC_LAYERS } from "../../config/mapLayers"
import { BsCardImage, BsDoorOpen, BsArrowLeftSquare, BsCompass } from "react-icons/bs"

const GeocoderControl = () => {
  const map = useMap() // Obtén el mapa de react-leaflet

  useEffect(() => {
    if (map) {
      // Asegúrate de no duplicar el control y añadirlo una sola vez
      const geocoderControl = L.Control.geocoder({
        geocoder: L.Control.Geocoder.nominatim(),  //L.Control.Geocoder.google(), 
        defaultMarkGeocode: false,  // Mostrar los resultados de la búsqueda en el mapa
        collapsed: true,          // Mostrar el control expandido por defecto        
      })
        .on('markgeocode', function (e) {
          const latlng = e.geocode.center   // Obtén las coordenadas del resultado
          const address = e.geocode.html  // Dirección obtenida del geocodificador

          const marker = markerSvg(latlng, '#ff0000').addTo(map)
          marker.bindPopup(address).openPopup()
          map.fitBounds(e.geocode.bbox)
        })
        .addTo(map)

      // Devuelve una función de limpieza para eliminar el control cuando el componente se desmonte
      return () => {
        if (geocoderControl) {
          map.removeControl(geocoderControl)
        }
      }
    }
  }, [map]) // Ejecutar cada vez que el mapa se cree o se actualice

  return null // Este componente no necesita renderizar nada
}

const MapOverCanvas = ({ canvasRef, zIndex = -10, onChangeMapIndex }) => {
  const intl = useIntl()
  let mapRef = useRef()
  const [mapIndex, setMapIndex] = useState(zIndex)
  const [imgGenerating, setImgGenerating] = useState(false)

  useEffect(() => {
    setMapIndex(zIndex)
  }, [zIndex])

  useEffect(() => {
    if (!mapRef) return
    // Acceder al DOM después de que el mapa se monte
    const zoomControl = document.querySelector(".leaflet-top.leaflet-left")
    const layersControl = document.querySelector(".leaflet-top.leaflet-right")

    // Añadir el atributo data-html2canvas-ignore a los controles
    if (zoomControl) {
      zoomControl.setAttribute("data-html2canvas-ignore", "true")
    }

    if (layersControl) {
      layersControl.setAttribute("data-html2canvas-ignore", "true")
    }
  }, [])

  const handleAddMapToCanvas = async (e) => {
    e.preventDefault()   // Detiene la acción por defecto
    e.stopPropagation()  // Evita que el evento se propague a los elementos padres

    if (!mapRef.current) {
      console.error('El mapa no está inicializado todavía.')
      return
    }
    setImgGenerating(true)
    const mapImage = await exportMapToImage(mapRef, canvasRef.width, canvasRef.height)
    if (mapImage) {
      await canvasRef.setBackgroundImage(
        mapImage,
        canvasRef.renderAll.bind(canvasRef),
        {
          scaleX: canvasRef.width / window.innerWidth,
          scaleY: canvasRef.height / window.innerHeight,
        }
      )
      onChangeMapIndex(-10)
    }
    setImgGenerating(false)
  }

  const handleCloseMap = () => {
    onChangeMapIndex(-10)
  }

  if (!canvasRef) return
  return (
    <div
      style={{
        position: "absolute",
        width: canvasRef.getWidth(),
        height: canvasRef.getHeight(),
        top: '0px',
        left: '0px',
        zIndex: mapIndex, // Asegúrate de que el mapa esté sobre el canvas
        pointerEvents: "all", // Permitir interacción con el mapa
      }
      }
    >
      <MapContainer
        ref={mapRef}
        center={[37.67119, -1.7017]}
        zoom={13}
        style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: "100%",
          height: "100%",
        }}
        attributionControl={false}
        scrollWheelZoom={!imgGenerating}     // Deshabilitar el zoom con la rueda del ratón
        dragging={!imgGenerating}            // Deshabilitar el arrastre (mover el mapa)
        zoomControl={!imgGenerating}         // Deshabilitar los controles de zoom
        doubleClickZoom={!imgGenerating}     // Deshabilitar el doble clic para hacer zoom
        boxZoom={!imgGenerating}             // Deshabilitar el zoom mediante selección de área        
        rotate={true}                        // Deshabilitar el map rotate        
        touchRotate={true}
        rotateControl={{
          closeOnZeroBearing: false,
          //position: 'bottomLeft',
        }}
      //bearing={30}
      >

        <LayersControl position="topright" collapsed={true}>
          <LayersControl.BaseLayer
            key={"lc-baselayer-OSM"}
            checked={true}
            name={OPENSTREETMAP_LAYER.name}
          >
            <TileLayer
              attribution={OPENSTREETMAP_LAYER.attribution}
              url={OPENSTREETMAP_LAYER.url}
              maxZoom={!OPENSTREETMAP_LAYER.maxZoom ? 18 : OPENSTREETMAP_LAYER.maxZoom}
              minZoom={!OPENSTREETMAP_LAYER.minZoom ? 1 : OPENSTREETMAP_LAYER.minZoom}
            />
          </LayersControl.BaseLayer>

          {BASIC_LAYERS && BASIC_LAYERS.map((layer, key) => {
            switch (layer.type) {
              case 'base':
                return (
                  <LayersControl.BaseLayer
                    key={"lc-baselayer-" + key}
                    checked={false}
                    name={layer.name}
                  >
                    <TileLayer
                      attribution={layer.attribution}
                      url={layer.url}
                      maxZoom={!layer.maxZoom ? 18 : layer.maxZoom}
                      minZoom={!layer.minZoom ? 1 : layer.minZoom}
                    />
                  </LayersControl.BaseLayer>
                )
              case 'wms':
                return (
                  <LayersControl.Overlay
                    key={"lc-overlay-" + key}
                    checked={false}
                    name={layer.name}
                  >
                    <WMSTileLayer
                      attribution={layer.attribution}
                      url={layer.url}
                      transparent={layer.transparent}
                      format={layer.format}
                      layers={layer.layers}
                      maxZoom={!layer.maxZoom ? 18 : layer.maxZoom}
                      minZoom={!layer.minZoom ? 1 : layer.minZoom}
                      eventHandlers={{
                        add: (tile) => {
                          //console.log("Added WMS")
                        },
                        remove: (tile) => {
                          //console.log("Removed WMS")
                        }
                      }}
                    />
                  </LayersControl.Overlay>
                )
              default:
                break
            }
          })}
        </LayersControl>
        <AttributionControl position="bottomleft" />

        <GeocoderControl />

        <ButtonGroup
          vertical
          style={{ zIndex: 99999, top: '100px', left: '10px', position: 'absolute' }}
          data-html2canvas-ignore="true"
        >
          <Button
            variant="light"
            onClick={handleCloseMap}
            title={intl.formatMessage({ id: "exit", defaultMessage: "Exit" })}
            disabled={imgGenerating}
          >
            <BsArrowLeftSquare size={20} />
          </Button>
          <Button
            variant="light"
            onClick={handleAddMapToCanvas}
            title={intl.formatMessage({ id: "setAsBackground", defaultMessage: "Set as background" })}
            disabled={imgGenerating}
          >

            <BsCardImage size={20} />
          </Button>

          <Button
            variant="light"
            //onClick={() => setRotation((r) => r + 15)}
            title={intl.formatMessage({ id: "setAsBackground", defaultMessage: "Set as background" })}
            disabled={imgGenerating}
          >

            <BsCompass size={20} />
          </Button>
        </ButtonGroup>

        <Alert
          hidden={!imgGenerating}
          variant='warning'
          style={{
            zIndex: 99999,
            position: 'absolute', // Añadir posición para permitir que se mueva
            top: `${window.innerHeight / 2}px`,
            left: `${window.innerWidth / 2}px`,
            transform: 'translate(-50%, -50%)' // Esto lo centra correctamente en la ventana
          }}
        >
          <Alert.Heading><FormattedMessage id="downloading" defaultMessage="Downloading" /></Alert.Heading>
          <FormattedMessage id="generatingBackgroundImage" defaultMessage="Generating background image. Please wait!..." /></Alert>
      </MapContainer>
    </div>
  )
}

export default MapOverCanvas
