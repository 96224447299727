import React, { useState } from 'react'
import { useIntl } from "react-intl"
import { Button, ButtonGroup } from 'react-bootstrap'
import { BsQuestionCircle } from 'react-icons/bs'
import CopyModal from '../modal/CopyModal'

const HelpControl = ({ label, parentNode }) => {
    const intl = useIntl()
    const [showModal, setShowModal] = useState(false)

    const toggleShowModal = () => {
        setShowModal(!showModal)
    }

    return (
        <>
            <ButtonGroup size='sm' aria-label="Help controls">
                <Button
                    title={intl.formatMessage({ id: "help", defaultMessage: "Help" })}
                    variant="light"
                    size='sm'
                    className="d-inline-flex align-items-center"
                    onClick={() => toggleShowModal()}
                >
                    <BsQuestionCircle />
                </Button>
            </ButtonGroup>
            <CopyModal title={label} show={showModal} onClose={() => toggleShowModal()} parentNode={parentNode} />
        </>

    )
}

export default HelpControl