import React from 'react'
import { ButtonGroup, Col, Container, Row } from 'react-bootstrap'
import DeleteControl from '../controls/DeleteControl'
import BackGroundControl from '../controls/BackGroundControl'
import LockControl from '../controls/LockControl'
import ScaleControl from '../controls/ScaleControl'
import DrawSectorControl from '../controls/DrawSectorControl'
import ElementsControl from '../controls/ElementsControl'

const ActionsMenu = ({ containerRef, canvasRef, onChangeMapIndex }) => {
    return (
        <Container
            className="mb-3"
            aria-label="Canvas actions control"
            style={{
                position: 'absolute',
                top: '100px',
                left: '10px',
                display: 'inline-block', // Asegura que el tamaño se ajuste al contenido
                maxWidth: 'none', // Elimina restricciones de ancho
                width: 'auto', // No ocupará más espacio del necesario
            }}
        >
            <Row>
                <Col>
                    <ButtonGroup
                        vertical
                        size='sm'
                        style={{
                            border: '1px solid #ddd', // Borde gris
                            borderRadius: '6px',     // Bordes redondeados    
                        }}
                    >
                        <LockControl canvasRef={canvasRef} />

                        <ScaleControl canvasRef={canvasRef} />

                        <BackGroundControl parentNode={containerRef} canvasRef={canvasRef} onMapSelection={onChangeMapIndex} />

                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup
                        vertical
                        size='sm'
                        className='mt-3'
                        style={{
                            border: '1px solid #ddd', // Borde gris
                            borderRadius: '6px',     // Bordes redondeados    
                        }}
                    >
                        <DrawSectorControl parentNode={containerRef} canvasRef={canvasRef} />

                        <ElementsControl parentNode={containerRef} canvasRef={canvasRef} />

                        <DeleteControl parentNode={containerRef} canvasRef={canvasRef} />

                    </ButtonGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default ActionsMenu