import React from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { Dropdown, ButtonGroup, Form } from 'react-bootstrap'
import { BsFileEarmarkImage, BsImageAlt, BsMap, BsPaintBucket } from 'react-icons/bs'

const BackGroundControl = ({ canvasRef, onMapSelection }) => {
    const intl = useIntl()

    // Handle Background File Change
    const handleFileChange = async (event) => {
        const file = event.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = () => {
                const base64DataUrl = reader.result
                canvasRef.setBackgroundImage(base64DataUrl)
            }
            reader.readAsDataURL(file)
        }
    }

    // Handle Background Color Change
    const handleColorChange = async (event) => {
        const color = event.target.value
        if (color) {
            canvasRef.setBackgroundColor(color)
        }
    }

    // Handle Background Map Select
    const handleMapSelect = () => {
        onMapSelection(10)
    }

    return (
        <Dropdown
            variant='secondary'
            as={ButtonGroup}
            drop='end'
        >
            <Dropdown.Toggle variant="light" size='md' id="dropdown-basic" title={intl.formatMessage({ id: "setBackgroundImage", defaultMessage: "Set background image" })}>
                <BsImageAlt size={20} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Header><FormattedMessage id="setBackground" defaultMessage="Set background" /></Dropdown.Header>
                <Dropdown.Item
                    onClick={handleMapSelect}
                    title={intl.formatMessage({ id: "setBackgroundMap", defaultMessage: "Set background map" })}
                >
                    <BsMap />&nbsp;<FormattedMessage id="fromMap" defaultMessage="From map" />
                </Dropdown.Item>
                <Dropdown.Item
                    as="label"
                    htmlFor="imageFileBg"
                    title={intl.formatMessage({ id: "setBackgroundImage", defaultMessage: "Set background image" })}
                    style={{ cursor: 'pointer' }}                     
                >
                    <BsFileEarmarkImage />&nbsp;<FormattedMessage id="fromImage" defaultMessage="From image" />
                    <Form.Control
                        id="imageFileBg"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                </Dropdown.Item>
                <Dropdown.Item
                    as="label"
                    htmlFor="colorField"
                    title={intl.formatMessage({ id: "setBackgroundColor", defaultMessage: "Set background color" })}
                    style={{ cursor: 'pointer' }}                     
                >
                    <BsPaintBucket />&nbsp;<FormattedMessage id="fromColor" defaultMessage="From color" />
                    <Form.Control
                        id="colorField"
                        type="color"
                        defaultValue="#563d7c"
                        onChange={handleColorChange}
                        style={{ display: 'none' }}
                    />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default BackGroundControl
