import React, { useState } from 'react'
import { useIntl } from "react-intl"
import { Button } from 'react-bootstrap'
import { BsLock, BsUnlock } from 'react-icons/bs'

const LockControl = ({ canvasRef }) => {
    const intl = useIntl()
    const [isLocked, setIsLocked] = useState(false)

    const handleLock = (e) => {
        e.preventDefault()   // Detiene la acción por defecto
        e.stopPropagation()  // Evita que el evento se propague a los elementos padres

        setIsLocked((prevLocked) => {
            const newLockedState = !prevLocked
            canvasRef.toggleCanvasLock(newLockedState) // Enviar el nuevo estado al método
            return newLockedState
        })
    }

    return (
        <Button
            variant={isLocked ? 'warning' : 'light'}
            size='sm'
            onClick={handleLock}
            title={isLocked ? intl.formatMessage({ id: "unlockCanvas", defaultMessage: "Unlock canvas" }) : intl.formatMessage({ id: "lockCanvas", defaultMessage: "Lock canvas" })}
        >
            {isLocked ? <BsLock size={20} /> : <BsUnlock size={20} />}
        </Button>
    )
}

export default LockControl
