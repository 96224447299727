import { BsExclamationTriangleFill, BsFire, BsPeopleFill, BsPersonBadge, BsSignMergeRight, BsWater } from 'react-icons/bs'

export const COLORS_CODE = {
    PEOPLE: {
        id: 'people',
        name: 'green',
        color: '#008000',
        fill: '#008000',
        rgba: 'rgba(0, 128, 0, 0.1)', // Verde
        icon: <BsPeopleFill />, // Ícono como componente
        selectedColor: '#008000',
        fontColor: '#008000',
        fontSize: 18,
        fontFamily: 'Arial',
    },
    FIRE: {
        id: 'fire',
        name: 'red',
        color: '#FF0000',
        fill: '#FF0000',
        rgba: 'rgba(255, 0, 0, 0.1)', // Rojo
        icon: <BsFire />, // Ícono como componente
        selectedColor: '#FF0000',
        fontColor: '#FF0000',
        fontSize: 18,
        fontFamily: 'Arial',
    },
    WATER: {
        id: 'water',
        name: 'blue',
        color: '#4169E1',
        fill: '#4169E1',
        rgba: 'rgba(65, 105, 225, 0.1)', // Azul (RoyalBlue)
        icon: <BsWater />, // Ícono como componente
        selectedColor: '#4169E1',
        fontColor: '#4169E1',
        fontSize: 18,
        fontFamily: 'Arial',
    },
    RISK: {
        id: 'risk',
        name: 'orange',
        color: '#FFC300',
        fill: '#FFC300',
        rgba: 'rgba(255, 195, 0, 0.1)', // Naranja
        icon: <BsExclamationTriangleFill />, // Ícono como componente
        selectedColor: '#FFC300',
        fontColor: '#FFC300',
        fontSize: 18,
        fontFamily: 'Arial',
    },
    COMMAND: {
        id: 'command',
        name: 'rebeccapurple',
        color: '#663399',
        fill: '#663399',
        rgba: 'rgba(102, 51, 153, 0.1)', // Púrpura
        icon: <BsPersonBadge />, // Ícono como componente
        selectedColor: '#663399',
        fontColor: '#663399',
        fontSize: 18,
        fontFamily: 'Arial',
    },
    DEFAULT: {
        id: 'default',
        name: 'black',
        color: '#000000',
        fill: '#000000',
        rgba: 'rgba(0, 0, 0, 0.1)', // Negro
        icon: <BsSignMergeRight />, // Ícono como componente
        selectedColor: '#000000',
        fontColor: '#000000',
        fontSize: 18,
        fontFamily: 'Arial',
    },
}
