import React, { useState, useEffect } from 'react'
import { useIntl } from "react-intl"
import { Button } from 'react-bootstrap'

const DateTimeControl = () => {
    const intl = useIntl()

    // Función para obtener la fecha y hora actuales
    const getCurrentDateTime = () => {
        const now = new Date()
        const day = now.toLocaleDateString() // Fecha en formato 'DD/MM/YYYY' (depende de la configuración regional)
        const time = now.toLocaleTimeString() // Hora en formato 'HH:MM:SS'
        return `${day} ${time}` // Devuelve una cadena con la fecha y hora
    }

    const [dateTime, setDateTime] = useState(getCurrentDateTime()) // Estado para la fecha y hora

    // Actualizamos la fecha y hora cada segundo
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDateTime(getCurrentDateTime())
        }, 1000)

        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalId)
    }, []) // Se ejecuta solo una vez, cuando el componente se monta

    return (
        <Button
            title={intl.formatMessage({ id: "dateTime", defaultMessage: "Date time" })}
            variant="light"
            size='sm'
            className="d-inline-flex align-items-center"
            disabled
        >
            {dateTime}
        </Button>
    )
}

export default DateTimeControl