import html2canvas from 'html2canvas'

const exportMapToImage = async (mapRef, width, height) => {
    if (!mapRef.current) {
        console.error('El mapa no está inicializado')
        return null
    }
    const mapContainer = mapRef.current.getContainer()

    mapRef.current.invalidateSize()

    const canvas = await html2canvas(mapContainer, {
        width: width,
        height: height,
        useCORS: true, // Para tiles externos
    })

    return canvas.toDataURL('image/png')
}

export default exportMapToImage