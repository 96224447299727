import L from "leaflet"

export const markerSvg = (latlng, color) => {
    return L.marker(latlng, { icon: markerIcon(color) })
}

export const markerIcon = (color) => {
    return L.divIcon({
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" fill="${color}" class="bi bi-geo-alt-fill" viewBox="0 0 16 16" data-html2canvas-ignore="true">
                <path fill="${color}" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                </svg>`,
        iconSize: [41, 41],
        iconAnchor: [20, 41],
        className: 'custom-marker-icon',
    })
}

export const personSvg = (latlng, color) => {
    return L.marker(latlng, { icon: personIcon(color) })
}

export const personIcon = (color) => {
    return L.divIcon({
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" fill="${color}" class="bi bi-person-circle" viewBox="0 0 16 16" data-html2canvas-ignore="true">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                </svg>`,
        iconSize: [41, 41],
        iconAnchor: [20, 20],
        className: { 'text-align': 'center', 'line-height': '41px' }
    })
}

export default markerSvg