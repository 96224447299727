import React, { useState } from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { Dropdown, ButtonGroup, Button, Row, Col, Form } from 'react-bootstrap'
import { BsAspectRatio, BsDash, BsDashSquare, BsPlus } from 'react-icons/bs'

const ScaleControl = ({ canvasRef }) => {
    const intl = useIntl()
    const [scale, setScale] = useState(1)

    const handleScaleChange = (e) => {
        e.preventDefault()   // Detiene la acción por defecto
        e.stopPropagation()  // Evita que el evento se propague a los elementos padres

        const newScale = parseFloat(e.target.value)
        setScale(newScale)
        canvasRef.setScaleAll(newScale)
    }

    const handleScalePlus = (e) => {
        e.preventDefault()   // Detiene la acción por defecto
        e.stopPropagation()  // Evita que el evento se propague a los elementos padres

        const canvasScale = canvasRef.getScale()
        const newScale = Math.min(canvasScale + 0.1, 2)
        setScale(newScale)
        canvasRef.setScaleAll(newScale)
    }

    const handleScaleDash = (e) => {
        e.preventDefault()   // Detiene la acción por defecto
        e.stopPropagation()  // Evita que el evento se propague a los elementos padres

        const canvasScale = canvasRef.getScale()
        const newScale = Math.max(canvasScale - 0.1, 0.1)
        setScale(newScale)
        canvasRef.setScaleAll(newScale)
    }

    return (
        <Dropdown as={ButtonGroup} drop="end" aria-label={intl.formatMessage({ id: "adjustScale", defaultMessage: "Adjust scale" })}>
            <Dropdown.Toggle variant="light" size="md" id="scale-control-dropdown">
                <BsAspectRatio size={20} title={intl.formatMessage({ id: "adjustScale", defaultMessage: "Adjust scale" })} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Header className='text-center'><FormattedMessage id="currentScale" defaultMessage="Current Scale:" /> {scale.toFixed(1)}</Dropdown.Header>
                <Dropdown.ItemText className='p-0'>
                    <Row className="align-items-center p-1">
                        <Col xs="auto">
                            <Button
                                onClick={handleScaleDash}
                                size='sm'
                                variant='light'
                            >
                                <BsDash />
                            </Button>
                        </Col>
                        <Col className='p-0'>
                            <Form.Range
                                value={scale}
                                onChange={handleScaleChange}
                                min={0.1}
                                max={2}
                                step={0.1}                                
                                className="align-self-center"
                            />
                        </Col>
                        <Col xs="auto">
                            <Button
                                onClick={handleScalePlus}
                                size='sm'
                                variant='light'
                            >
                                <BsPlus />
                            </Button>
                        </Col>
                    </Row>
                </Dropdown.ItemText>               
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ScaleControl
