import React from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { NavDropdown } from 'react-bootstrap'
import { BsThreeDotsVertical, BsPlusCircle, BsCardList, BsArrowRepeat } from 'react-icons/bs'

const SitacControl = ({ parentNode }) => {
    const intl = useIntl()
    return (
        <NavDropdown
            menuVariant='light'
            aria-label={intl.formatMessage({ id: "sitacControls", defaultMessage: "SITAC controls" })}
            title={
                <>
                    <span className="d-none d-lg-inline-flex align-items-center">
                        <BsThreeDotsVertical size={20} title={intl.formatMessage({ id: "sitacManagement", defaultMessage: "SITACs management" })} />
                    </span>
                    <span className="d-block d-lg-none d-inline-flex align-items-center">
                        <BsThreeDotsVertical size={20} title={intl.formatMessage({ id: "sitacManagement", defaultMessage: "SITACs management" })} />&nbsp;<FormattedMessage id="sitacs" defaultMessage="SITACs" />
                    </span>
                </>
            }
        >
            <NavDropdown.Item
                className="d-flex align-items-center"
                title={intl.formatMessage({ id: "newSitac", defaultMessage: "New SITAC" })}
                disabled
            >
                <BsPlusCircle />&nbsp;<FormattedMessage id="newSitac" defaultMessage="New SITAC" />
            </NavDropdown.Item>
            <NavDropdown.Item
                className="d-flex align-items-center"
                title={intl.formatMessage({ id: "mySitacs", defaultMessage: "My SITACs" })}
                disabled
            >
                <BsCardList />&nbsp;<FormattedMessage id="mySitacs" defaultMessage="My SITACs" />
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
                className="d-flex align-items-center"
                title={intl.formatMessage({ id: "synchronizeSitacs", defaultMessage: "Synchronize SITACs" })}
                disabled
            >
                <BsArrowRepeat />&nbsp;<FormattedMessage id="synchronizeSitacs" defaultMessage="Synchronize SITACs" />
            </NavDropdown.Item>
        </NavDropdown>
    )
}

export default SitacControl
