import React from 'react'

const ControlsMenu = ({ buttons = [], position = { top: null, left: null, bottom: null, right: null, direction: 'row' }, backgroundColor = '#fff' }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: position.top ? position.top : 'none',  // Distancia desde la parte superior
        left: position.left ? position.left : 'none',    // Distancia desde la parte izquierda
        bottom: position.bottom ? position.bottom : 'none',  // Distancia desde la parte inferior
        right: position.right ? position.right : 'none',    // Distancia desde la parte derecha
        backgroundColor: backgroundColor,
        display: 'flex',
        flexDirection: position.direction,  // Alinea los botones verticalmente
        alignItems: 'center',     // Centra los botones verticalmente
        gap: '10px',              // Espacio entre botones
        zIndex: 10,               // Asegura que los botones estén encima del canvas
        padding: '5px',          // Espacio interno alrededor de los botones
        border: '1px solid #ddd', // Borde gris
        borderRadius: '4px',     // Bordes redondeados
      }}
    >
      {buttons.map((Button, index) => (
        <div key={index}>{Button}</div>
      ))}
    </div>
  )
}

export default ControlsMenu