// Import necessary dependencies
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from "react-intl"
import GomFabric from './lib/GomFabric'
import HomeMenu from './components/menu/HomeMenu'
import ActionsMenu from './components/menu/ActionsMenu'
import InfoMenu from './components/menu/InfoMenu'
import ToolsMenu from './components/menu/ToolsMenu'

import MapOverCanvas from './components/map/MapOverCanvas'

const GomCanvas = ({ width = '100%', height = '100%' }) => {
  const intl = useIntl()
  
  // Ref to hold the canvas DOM element
  const containerRef = useRef(null)
  const canvasRef = useRef(null)
  const gomCanvasRef = useRef(null)

  useEffect(() => {
    // Initialize the GomFabric.js canvas
    const gomCanvas = new GomFabric(canvasRef, {
      locationName: intl.formatMessage({ id: "locationEventName", defaultMessage: "Location or event name" }),
      backgroundColor: '#f0f0f0',
      width: window.innerWidth,
      height: window.innerHeight,
      grid: {
        size: 25,
        color: '#e0e0e0',
        opacity: 0.8
      }
    })

    gomCanvasRef.current = gomCanvas

    // Add a simple rectangle to test
    gomCanvas.addRectangle1({
      fill: 'red',
      width: 100,
      height: 100,
      position: {
        left: 100,
        top: 100
      }
    }, containerRef.current)
    // Add a simple rectangle to test
    gomCanvas.addRectangle1({
      fill: 'blue',
      width: 100,
      height: 100,
      position: {
        left: 250,
        top: 100
      }
    }, containerRef.current)

    // Clean-up function: eliminar la instancia de Canvas al desmontar
    return () => {
      gomCanvas.dispose()
    }
  }, [])

  useEffect(() => {
    // Resize canvas on window resize
    const handleResize = () => {
      if (gomCanvasRef.current) {
        gomCanvasRef.current?.resize({ width: window.innerWidth, height: window.innerHeight })
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // MapOverCanvas show controls
  const [mapIndex, setMapIndex] = useState(null)
  const handleMapIndexChange = (index) => {
    setMapIndex(index)
  }

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: `${width}px`,
        height: `${height}px`,
        border: '0px',
      }}
    >

      <canvas
        ref={canvasRef}
        style={{ display: 'block', margin: 0, padding: 0 }}
      />

      {/* Botones arriba izquierda LOGO/PROJECT/USER */}
      <HomeMenu containerRef={containerRef.current} canvasRef={gomCanvasRef.current} />

      {/* Botones izquierda/medio CANVAS ACTIONS */}
      <ActionsMenu containerRef={containerRef.current} canvasRef={gomCanvasRef.current} onChangeMapIndex={handleMapIndexChange} />

      {/* Botones arriba derecha Project INFO */}
      <InfoMenu containerRef={containerRef.current} canvasRef={gomCanvasRef.current} />

      {/* Botones abajo derecha CANVAS GRID/ZOOM/HELP/DATETIME */}
      <ToolsMenu containerRef={containerRef.current} canvasRef={gomCanvasRef.current} />

      {/* Mapa para tomar capturas fondo canvas */}
      {mapIndex &&
        <MapOverCanvas canvasRef={gomCanvasRef.current} zIndex={mapIndex} onChangeMapIndex={handleMapIndexChange} />
      }
    </div>
  )
}

export default GomCanvas
