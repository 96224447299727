import React, { useEffect, useState } from 'react'
import { useIntl } from "react-intl"
import { Button, ButtonGroup } from 'react-bootstrap'
import { BiFullscreen, BiExitFullscreen } from 'react-icons/bi'


const FullscreenControl = ({ parentNode }) => {
    const intl = useIntl()
    const [isFullscreenActive, setIsFullscreenActive] = useState(false)

    const handleFullScreenToggle = () => {
        const canvasContainer = parentNode

        // Comprobar si estamos en modo fullscreen
        if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
            // Si no estamos en fullscreen, activarlo
            setIsFullscreenActive(true)
            if (canvasContainer.requestFullscreen) {
                canvasContainer.requestFullscreen()
            } else if (canvasContainer.webkitRequestFullscreen) {
                canvasContainer.webkitRequestFullscreen()
            } else if (canvasContainer.msRequestFullscreen) {
                canvasContainer.msRequestFullscreen()
            }
        } else {
            // Si ya estamos en fullscreen, salir de él
            setIsFullscreenActive(false)
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            }
        }
    }

    useEffect(() => {
        // Controlar cambios en el estado fullscreen
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullscreenActive(false)
            }
        }

        document.addEventListener("fullscreenchange", handleFullscreenChange)
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange)
        document.addEventListener("mozfullscreenchange", handleFullscreenChange)

        return () => {
            // Limpiar eventos
            document.removeEventListener("fullscreenchange", handleFullscreenChange)
            document.removeEventListener("webkitfullscreenchange", handleFullscreenChange)
            document.removeEventListener("mozfullscreenchange", handleFullscreenChange)
        }
    }, [])

    return (
        <ButtonGroup size='sm' aria-label="Fullscreen controls">
            <Button
                title={intl.formatMessage({ id: "fullScreen", defaultMessage: "Full screen" })}
                variant={'light'}
                size='sm'
                onClick={handleFullScreenToggle}
                className="d-inline-flex align-items-center"
            >
                {isFullscreenActive ? (
                    <BiExitFullscreen size={20} />
                ) : (
                    <BiFullscreen size={20} />
                )}
            </Button>
        </ButtonGroup>
    )
}

export default FullscreenControl