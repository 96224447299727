import React, { useState } from 'react'
import { useIntl } from "react-intl"
import { ButtonGroup, Button } from 'react-bootstrap'
import { BsZoomIn, BsZoomOut } from 'react-icons/bs'

const ZoomControl = ({ canvasRef }) => {
    const intl = useIntl()
    const [zoom, setZoom] = useState(1)
    const [btnZoomOutDisabled, setBtnZoomOutDisabled] = useState(false)
    const [btnZoomInDisabled, setBtnZoomInDisabled] = useState(false)

    const handleZoom = (newZoom) => {
        // Limitar el rango del zoom
        newZoom = Math.min(1.9, Math.max(0.1, newZoom))

        // Actualizar el estado del zoom
        setZoom(newZoom)
        canvasRef.setZoom(newZoom)

        // Deshabilitar/enabled botones según el rango del zoom
        setBtnZoomOutDisabled(newZoom <= 0.1)
        setBtnZoomInDisabled(newZoom >= 1.9)
    }

    const handleZoomOut = () => {
        handleZoom(zoom - 0.1)
    }

    const handleZoomIn = () => {
        handleZoom(zoom + 0.1)
    }

    const handleResetZoom = () => {
        handleZoom(1)
    }

    return (
        <ButtonGroup size='sm' aria-label="Zoom controls">
            <Button
                variant='light'
                size='sm'
                onClick={handleZoomOut}
                title={intl.formatMessage({ id: "zoomOut", defaultMessage: "Zoom out" })}
                disabled={btnZoomOutDisabled ? 'disabled' : ''}
                className="d-inline-flex align-items-center"
            >
                <BsZoomOut />
            </Button>
            <Button
                variant='light'
                size='sm'
                onClick={handleResetZoom}
                title={intl.formatMessage({ id: "resetZoom", defaultMessage: "Reset Zoom" })}
                className="d-inline-flex align-items-center"
            >
                {parseInt(zoom * 100 / 1)}%
            </Button>
            <Button
                variant='light'
                size='sm'
                onClick={handleZoomIn}
                title={intl.formatMessage({ id: "zoomIn", defaultMessage: "Zoom in" })}
                disabled={btnZoomInDisabled ? 'disabled' : ''}
                className="d-inline-flex align-items-center"
            >
                <BsZoomIn />
            </Button>
        </ButtonGroup>
    )
}

export default ZoomControl
