import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import { Dropdown, ButtonGroup, Accordion, ListGroup } from 'react-bootstrap'
import { BsBoxSeam, BsTrash, BsTrashFill, BsXCircleFill } from 'react-icons/bs'

const ElementsControl = ({ canvasRef }) => {
    const intl = useIntl()
    const [elementSelected, setElementSelected] = useState(false)

    const handleDeleteSelected = () => {
        if (canvasRef) {
            canvasRef.deleteSelected()
        }
    }

    const handleDeleteAll = () => {
        if (canvasRef) {
            canvasRef.deleteAll()
        }
    }

    // useEffect para actualizar `elementSelected` cuando el canvas cambia
    useEffect(() => {
        if (!canvasRef) return
        const onObjectSelected = (e) => {
            setElementSelected(true) // Hay objeto seleccionado
        }

        const onSelectionCleared = () => {
            setElementSelected(false) // No hay objeto seleccionado
        }

        // Suscribir los eventos de selección
        canvasRef.addOn('selection:created', onObjectSelected)
        canvasRef.addOn('selection:cleared', onSelectionCleared)

        // Limpiar los eventos cuando el componente se desmonte
        return () => {
            canvasRef.addOff('selection:created')
            canvasRef.addOff('selection:cleared')
        }
    }, [canvasRef])

    const handleAddElement = (elementType) => {
        //canvasRef.addElement({type: elementType})
        console.log("elemento a añadir:", elementType)
    }

    return (
        <Dropdown
            variant='secondary'
            as={ButtonGroup}
            drop='end'
            className='p-0'
        >
            <Dropdown.Toggle variant="light" size='md' id="dropdown-basic" title={intl.formatMessage({ id: "delete", defaultMessage: "Delete" })}>
                <BsBoxSeam size={20} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Header><FormattedMessage id="cartaGrafica" defaultMessage="Carta gráfica a cambiar" /></Dropdown.Header>
                <Dropdown.ItemText className='p-0 m-0'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Accordion Item #1</Accordion.Header>
                            <Accordion.Body>
                                <ListGroup>
                                    <ListGroup.Item action onClick={() => handleAddElement('square')}>
                                        Link 1
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={() => handleAddElement('circle')}>
                                        Link 2
                                    </ListGroup.Item>
                                </ListGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Accordion Item #2</Accordion.Header>
                            <Accordion.Body>
                                <ListGroup>
                                    <ListGroup.Item action href="#link1">
                                        Link 1
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#link2">
                                        Link 2
                                    </ListGroup.Item>
                                </ListGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Dropdown.ItemText>

                <Dropdown.Item
                    onClick={handleDeleteSelected}
                    disabled={!elementSelected}
                >
                    <BsXCircleFill />&nbsp;<FormattedMessage id="selected" defaultMessage="Delete" /></Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ElementsControl
