import React from 'react'
import ControlsMenu from './ControlsMenu'
import LastUpdateControl from '../controls/LastUpdateControl'

const InfoMenu = ({ containerRef, canvasRef }) => {
    return (
        <ControlsMenu
            buttons={[
                <LastUpdateControl canvasRef={canvasRef} />,
            ]}
            position={{
                top: '10px',
                right: '10px'
            }}
        />
    )
}

export default InfoMenu